/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { datadogRum } from '@datadog/browser-rum';
import { isArray } from '@ember/array';
import { pluralize } from 'ember-inflector';
import { service } from '@ember/service';
import ENV from 'tio-employee/config/environment';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import type Model from '@ember-data/model';
import type SessionService from '../services/session';
import type Store from '@ember-data/store';
import type LoanModel from 'tio-common/models/loan';
import type SessionContextService from 'tio-employee/services/session-context';

type Headers = {
  'x-api-key': string;
  'tio-auth-token'?: string;
};

/**
 * An adapter for all models within the application that are not overridden by
 * more specific adapters. This adapter receives requests from the store and
 * translates those into the appropriate actions to take against the API.
 *
 * @memberof adapters
 */
class ApplicationAdapter extends JSONAPIAdapter {
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;

  host = ENV.apiHost;

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers() {
    const headers: Headers = {
      'x-api-key': ENV.apiKey,
    };

    if (this.session.isAuthenticated) {
      headers['tio-auth-token'] = `${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  /**
    Override urlForUpdateRecord to support additional forms of json-api spec
    for saving records.

    When doing `model.save({adapterOptions: { updateRelationship: 'people' }});` the following will be generated.

    ```
    PATCH /articles/1/relationships/author HTTP/1.1
    Content-Type: application/vnd.api+json
    Accept: application/vnd.api+json

    {
      "data": { "type": "people", "id": "12" }
    }
    ```
  */
  urlForUpdateRecord(id: string, modelName: string, snapshot: Record<string, unknown>) {
    const originalEndpoint = super.urlForUpdateRecord(id, modelName, snapshot);

    const { adapterOptions } = snapshot;
    // @ts-expect-error: ED has no types
    if (adapterOptions && Array.isArray(adapterOptions.createHasMany)) {
      // @ts-expect-error: ED has no types
      const hasManyModel = adapterOptions?.createHasMany?.[0]?.constructor?.modelName;

      return `${originalEndpoint}/relationships/${pluralize(hasManyModel)}`;
    }

    // @ts-expect-error: ED has no types
    if (adapterOptions?.updateRelationship) {
      // @ts-expect-error: ED has no types
      return `${originalEndpoint}/relationships/${adapterOptions?.updateRelationship}`;
    }
    return originalEndpoint;
  }

  urlForDeleteRecord(id: string, modelName: string, snapshot: Record<string, unknown>) {
    const originalEndpoint = super.urlForDeleteRecord(id, modelName, snapshot);
    const { adapterOptions } = snapshot;

    // @ts-expect-error: ED has no types
    if (adapterOptions && isArray(adapterOptions.destroyHasMany)) {
      // @ts-expect-error: ED has no types
      const hasManyModel = adapterOptions?.destroyHasMany?.firstObject?.constructor?.modelName;

      return `${originalEndpoint}/relationships/${pluralize(hasManyModel)}`;
    }

    return originalEndpoint;
  }

  updateRecord(
    store: typeof Store,
    type: Record<string, unknown>,
    snapshot: Record<string, unknown>
  ) {
    const { adapterOptions } = snapshot;
    // @ts-expect-error: ED has no types
    if (adapterOptions && isArray(adapterOptions.createHasMany)) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      return this.ajax(url, 'POST', { data: data }).then(() => {
        return null;
      });
    }
    // @ts-expect-error: ED has no types
    if (adapterOptions && isArray(adapterOptions.destroyHasMany)) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'deleteRecord');

      return this.ajax(url, 'DELETE', { data: data }).then(() => {
        return null;
      });
    }

    return super.updateRecord(store, type, snapshot);
  }

  /**
   * This method is called for every response that the adapter receives from the
   * API. If the response has a 401 or 403 status code it invalidates the session (see
   *
   * @param {Number} status The response status as received from the API
   * @param  {Object} headers HTTP headers as received from the API
   * @param {Any} payload The response body as received from the API
   * @param {Object} requestData the original request information
   * @protected
   */
  handleResponse(
    status: number,
    headers: Record<string, unknown>,
    payload: Record<string, unknown>,
    requestData: Record<string, unknown>
  ) {
    this.ensureResponseAuthorized(status, headers, payload, requestData);
    return super.handleResponse(status, headers, payload, requestData);
  }

  /**
   * The default implementation for handleResponse.
   * If the response has a 401 or 403 status code it invalidates the session.
   * Override this method if you want custom invalidation logic for incoming responses.
   *
   * @param {Number} status The response status as received from the API
   * @param  {Object} headers HTTP headers as received from the API
   * @param {Any} payload The response body as received from the API
   * @param {Object} requestData the original request information
   */
  ensureResponseAuthorized(
    status: number,
    headers: unknown,
    payload: unknown,
    requestData: unknown
  ) {
    if ([401, 403].includes(status) && this.session.isAuthenticated) {
      console.error('ensureResponseAuthorized caught error', {
        status,
        headers,
        payload,
        requestData,
        userId: this.sessionContext.user.id,
      });

      datadogRum.addError(
        `ensureResponseAuthorized caught error status ${status} for user ${this.sessionContext.user.id}`,
        {
          status,
          headers,
          payload,
          requestData,
          userId: this.sessionContext.user.id,
        }
      );
      this.sessionContext.logout();
    }
  }

  /**
   * Queries the API for aggregate data that the `owner` has permission to access.
   *
   * @param {Model}  owner The model used to determine the access restrictions for the query.
   *                       Usually the current user
   * @param {object} query The query that defines what data should be returned
   *
   * @return {object} An array of aggregate data objects representing the aggregate data for the
   *                  model specified by `query.model`
   */
  async aggregate(owner: typeof Model, query: Record<string, unknown>) {
    if (query?.model === 'pslf-observations') {
      return this.aggregatePslfObservations(owner, query);
    }

    const url = this.buildURL(owner.constructor.modelName, owner.id) + '/aggregate';
    const response = await this.ajax(url, 'GET', { data: query });

    return response.data;
  }

  /**
   * HACK: Hack method for producing aggregate data for the PSLF observations.
   *       May not be accurate. [twl 2.Nov.22]
   */
  async aggregatePslfObservations(owner: typeof Model, query: Record<string, unknown>) {
    const url = this.buildURL('user', owner.id) + '/pslf-observations';
    const response = await this.ajax(url, 'GET', { data: query });

    // DEBUG: Use to add variability that doesn't exist in Vodka test data.
    // response.data.forEach(item => {
    //   item.qualified -= Math.round(Math.random() * 10, 0);
    //   item.unqualified -= Math.round(Math.random() * 10, 0);
    // });

    // Add the loan IDs if there is no other way to distinguish between
    // different loans
    const loanNames = new Set();
    const includeLoanIds = !!response.data.find((item: LoanModel) => {
      if (loanNames.has(item.aggregationLoanName)) {
        return true;
      }

      loanNames.add(item.aggregationLoanName);

      return false;
    });

    return response.data.map((item: LoanModel) => ({
      label:
        item.aggregationLoanName +
        (item.rawLoanType ? ` - ${item.rawLoanType}` : '') +
        (includeLoanIds ? ` (${item.aggregationLoanId})` : ''),
      data: [
        {
          name: 'Qualifying payment count ¹',
          value: item.qualified,
        },
        {
          name: 'Potentially qualifying payments ²',
          value: item.unqualified,
        },
        {
          name: 'Remaining payments for forgiveness',
          value: Math.max(0, 120 - item.qualified - item.unqualified),
        },
      ],
      meta: item,
    }));
  }

  /**
   * Queries the API for time series data  that the `owner` has permission to access.
   *
   * @param {Model}  owner The model used to determine the access restrictions for the query.
   *                       Usually the current user
   * @param {object} query The query that defines what time series data should be returned
   *
   * @return {object} An array of time series objects representing the time series data for the
   *                  `model` specified by `query.model`
   */
  async timeSeries(owner: typeof Model, query: Record<string, unknown>) {
    const url = this.buildURL(owner.constructor.modelName, owner.id) + '/time-series';
    const response = await this.ajax(url, 'GET', { data: query });

    return response.data;
  }
}

export default ApplicationAdapter;
