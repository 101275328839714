import { template } from "@ember/template-compiler";
import { or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import AuthRegisterEnrollForm from 'tio-employee/components/auth/register/enroll-form';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import LoginHere from 'tio-employee/components/auth/login-here';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import type { RegisterUser } from '../routes/enroll.ts';
import type EnrollController from '../controllers/enroll.ts';
import type IntlService from 'ember-intl/services/intl';
import type PreRegisterService from '../services/pre-register.ts';
interface EnrollRouteComponentSignature {
    Args: {
        model: RegisterUser | null;
        controller: EnrollController;
    };
}
let EnrollRoute = class EnrollRoute extends Component<EnrollRouteComponentSignature> {
    @service
    preRegister: PreRegisterService;
    @service
    intl: IntlService;
    get companySettings() {
        return this.preRegister.securitySettings;
    }
    get passwordMinLength() {
        return (this.args.model?.settings.security.passwordMinLength || this.companySettings.passwordMinLength || 8);
    }
    get isInviteExpired() {
        return this.args.model?.inviteExpired;
    }
    get isInviteError() {
        return !this.args.model || !this.args.model?.id;
    }
    get errorMessage() {
        if (this.isInviteExpired) {
            return this.intl.t('login.invite_expired');
        }
        return this.intl.t('login.invite_error');
    }
    static{
        template(`
    {{pageTitle (t "login.enroll")}}
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <EmailLookupWrapper @route="enroll">
        {{#if (or this.isInviteExpired this.isInviteError)}}
          <div class="text-center">
            <h1 class="text-xl mb-4">{{this.errorMessage}}</h1>
            <LoginHere />
          </div>
        {{else}}
          <div class="flex flex-col items-center">
            <h1 class="text-2xl mb-4">{{t "login.sign_up"}}</h1>
            <LoginHere />
          </div>
          <AuthRegisterEnrollForm
            @registerUser={{@model}}
            @email={{@controller.email}}
            @passwordMinLength={{this.passwordMinLength}}
          />
        {{/if}}
      </EmailLookupWrapper>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EnrollRoute);
